export default [
    {
                  id: "school1",
                  src:'images/p5/setup.png',
                  gallery:[
                    {
                      original: '../images/p5/setup.png',
                      thumbnail: '../images/p5/setup.png'
                    }, 
                    {
                      original: '../images/p5/2.png',
                      thumbnail: '../images/p5/2.png'
                    },
                    {
                      original: '../images/p5/red.png',
                      thumbnail: '../images/p5/red.png'
                    },
                    {
                      original: '../images/p5/cheer.png',
                      thumbnail: '../images/p5/cheer.png'
                    },
                    {
                      original: '../images/p5/snor.png',
                      thumbnail: '../images/p5/snor.png'
                    }
                  ],
                  text:'P5 js',
                  p:'Voor opdracht 1 moesten we een basis opdracht doen en zo de kleur van het canvas veranderen.  Daarna moesten we met de input van de camera iets leuks/ creatiefs maken. Ik heb ervoor gekozen om een snorretje op mijn gezicht te plakken die met mijn gezicht meebeweegt. Ook moesten we doormiddel van posenet en cheer functie maken die afging wanneer je ging juichen. Dit heb ik gedaan door een if statement te maken als mijn handen boven mijn ogen zijn dan print die iets uit in de console.   Als laaste moesten we iets doen met de afstand tussen twee element hier heb ik een foto van een accordeon gebruikt en die breder en smaller laten worden op de afstand tussen mijn armen. ',
                  label:'HT',
                  path:'/products/ps1'
                },
                {
                  id: "arduino",
                  src:'images/arduino/smile.png',
                  text:'Arduino',
                  label:'HT',
                  p:"",
                  gallery:[
                    {
                      original: '../images/Arduino/smile.png',
                      thumbnail: '../images/Arduino/smile.png'
                    }, 
                    {
                      original: '../images/Arduino/2.png',
                      thumbnail: '../images/Arduino/2.png'
                    },   
                    {
                      original: '../images/Arduino/3.png',
                      thumbnail: '../images/Arduino/3.png'
                    },  
                     {
                      original: '../images/Arduino/4.png',
                      thumbnail: '../images/Arduino/4.png'
                    },   
                    {
                      original: '../images/Arduino/5.png',
                      thumbnail: '../images/Arduino/5.png'
                    },  
                     {
                      original: '../images/Arduino/5.png',
                      thumbnail: '../images/Arduino/5.png'
                    },  
                     {
                      original: '../images/Arduino/6.png',
                      thumbnail: '../images/Arduino/6.png'
                    },   
                    {
                      original: '../images/Arduino/7.png',
                      thumbnail: '../images/Arduino/7.png'
                    },  
                     {
                      original: '../images/Arduino/8.png',
                      thumbnail: '../images/Arduino/8.png'
                    }, 
                  ],
                },
                  {
                  id: "school3",
                  src:'images/unity2D/start.png',
                  text:'Unity 2D platformer',
                  label:'HT',
                  path:'/products/ps2',
                  p:"Voor de eerste opdracht van unity heb ik ervoor gekozen om de tutorial te volgen van de 2D platformer, na dat ik alle stappen had gedaan van de tutorial ben ik bezig geweest met de extra tutorials die je kan doen via de site van unity. De extra tutorial die ik gevolgd hebt is voor het maken van een custom titel/ begin scherm.  ",
                  gallery:[
                    {
                      original: '../images/unity2D/start.png',
                      thumbnail: '../images/unity2D/start.png'
                    }, 
                    {
                      original: '../images/unity2D/2.png',
                      thumbnail: '../images/unity2D/2.png'
                    },   
                    {
                      original: '../images/unity2D/3.png',
                      thumbnail: '../images/unity2D/3.png'
                    },  
                     {
                      original: '../images/unity2D/4.png',
                      thumbnail: '../images/unity2D/4.png'
                    } ]
                },  
                  {
                  id: "school4",
                  src:'images/unityRacer/1.png',
                  text:'Unity Racer',
                  label:'HT',
                  p:'Bij deze unity tutorial heb ik eerst alles basis stappen gevolgd waarmee je leert hoe je bijvoorbeeld de auto sneller moest laten rijden of de auto groter maken, deze stappen waar vrij gemakkelijk en er basis. De tutorial had ik daarom ook al vrij snel af daarna ben ik zelf bezig geweest om verschillende autos toe te passen in het spel, ook ben ik verschillende opstakels en voorwerpen gaan plaatsen zoals een ramp.',
                  path:'/sign-up/rdhmd',
                  gallery:[
                    {
                      original: '../images/unityRacer/start.png',
                      thumbnail: '../images/unityRacer/start.png'
                    }, 
                    {
                      original: '../images/unityRacer/2.png',
                      thumbnail: '../images/unityRacer/2.png'
                    },   
                    {
                      original: '../images/unityRacer/3.png',
                      thumbnail: '../images/unityRacer/3.png'
                    },  
                      {
                      original: '../images/unityRacer/4.png',
                      thumbnail: '../images/unityRacer/4.png'
                    },
                    {
                      original: '../images/unityRacer/5.png',
                      thumbnail: '../images/unityRacer/5.png'
                    }]
                },
                {
                  id: "labweken",
                  src:'images/labweken/1.jpeg',
                  text:'labweken',
                  label:'HT',
                },
                {
                  id: "Excursie",
                  src:'images/Topics/museon.jpeg',
                  text:'One Planet, Museon',
                  p:'Voor de Excursie ben ik naar het museon geweest samen met mijn vriendin. Hier hadden ze expositie van Oneplanet dit gaat over 17 duurzame ontwikkelingsdoelen van de Verenigde Naties die zij voor 2030 willen bereiken. Zo gaat het over de hongersnood in verschillende landen en de verdeling van bijvoorbeeld water en energie. Ook kon je verschillende soort van spelletjes over verschillende themas zoals vers water maken of stroom generen. De expo was wel erg op kinderen gericht en niet zo zeer op volwassenen, het stond allemaal uitgelegt in jip en janneke taal en de interacties waren erg kinderlijk. Ook vond ik de expo niet zo groot, ik had het museon namelijk een stuk groter geschat dan dat het eigenlijk is. ',
                  label:'HT',
                  gallery:[
                    {
                      original: '../images/expeditie/1.png',
                      thumbnail: '../images/expeditie/1.png'
                    }, 
                    {
                      original: '../images/expeditie/2.png',
                      thumbnail: '../images/expeditie/2.png'
                    },
                    {
                      original: '../images/expeditie/3.png',
                      thumbnail: '../images/expeditie/3.png'
                    },
                    {
                      original: '../images/expeditie/4.png',
                      thumbnail: '../images/expeditie/4.png'
                    },
                    {
                      original: '../images/expeditie/5.png',
                      thumbnail: '../images/expeditie/5.png'
                    },
                    {
                      original: '../images/expeditie/6.png',
                      thumbnail: '../images/expeditie/6.png'
                    },]
                },
                {
                  id: "aframe",
                  src:'images/aframe/1/1.png',
                  text:'aframe',
                  p:'',
                  label:'HT',
                  gallery:[
                    {
                      original: '../images/unityRacer/start.png',
                      thumbnail: '../images/unityRacer/start.png'
                    }, 
                    {
                      original: '../images/unityRacer/2.png',
                      thumbnail: '../images/unityRacer/2.png'
                    },]
                },
               


]

