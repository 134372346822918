export const about = {
    id: 'about',
    lightBg: true,
    lightText: false,
    lightTextDesc: true,
    topLine: 'About',
    headLine:'Wie ben ik?',
    description: 'Ik ben Yustin Troost, ben 20 jaar en heb de opleiding Applicatie & Media Ontwikkelaar afgerond. op dit moment ben ik bezig met de opleiding Communicatie en Multimedia Design aan de Haagse Hogeschool.',
    buttonLabel: 'Lees meer',
    imgStart: true,
    img: '/images/photo.svg',
    alt:'me',
    dark:  true,
    primary: true,
    darkText: true,
    btnStyle: 'btn--inverted',
    btnTo:'about'
};

export const about2 = {
    id: 'about2',
    lightBg: true,
    lightText: false,
    lightTextDesc: true,
    topLine: 'about',
    headLine:'Wat doe ik?',
    description: 'Met mijn kennis over het ontwerpen van websites en diverse programmeertalen zoals HTML, CSS, PHP, JS en WordPress ben ik breed inzetbaar. Als alleskunner ga ik door tot iets af is.',
    buttonLabel: 'Lees meer',
    imgStart: true,
    img: '/images/Social.svg',
    alt:'me',
    dark:  true,
    primary: true,
    darkText: true,
    btnStyle: 'btn--inverted',
    btnTo:'portfolio'
};

export const facts = {
    id: 'portfolio',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'MY FEATURED SKILLS',
    headLine:'Paar interesante weetjes over mij',
    description: 'Met mijn kennis over het ontwerpen van websites en diverse programmeertalen zoals HTML, CSS, PHP, JS en WordPress ben ik breed inzetbaar. Als alleskunner ga ik door tot iets af is.',
    buttonLabel: 'Lees meer',
    imgStart: false,
    img: '/images//photo.svg',
    alt:'me',
    dark:  true,
    primary: true,
    darkText: false,
    btnStyle: 'btn--primary',
    btnTo:'portfolio'
};