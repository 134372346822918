export default [
      {
                    id: "0",
                    src:'images/Eslicht.jpg',
                    text:'Portrait shoot',
                    label:'Photography',
                    path:'/products/ps1'
                  },
                  {
                    id: "1",
                    src:'images/Energy21.jpg',
                    text:'Energy21 promo video',
                    label:'Video edit',
                    path:'/sign-up/e21'
                  },
                    {
                    id: "2",
                    src:'images/Portrait.jpg',
                    text:'Portrait shoot',
                    label:'Photography',
                    path:'/products/ps2'
                  },  
                    {
                    id: "3",
                    src:'images/8.jpg',
                    text:'Rainbow Den Haag mobile design',
                    label:'Video edit',
                    path:'/sign-up/rdhmd'
                  },
                    {
                    id: "4",
                    src:'images/pater.jpg',
                    text:'paterzonweringen webdesign and realisation',
                    label:'Photography',
                    path:'/products/pwr'
                  },
                    {
                    id: "5",
                    src:'images/Car.jpg',
                    text:'Automotive photograhpy shoot',
                    label:'Photography',
                    path:'/products/as1'
                  },
                    {
                    id: "6",
                    src:'images/peanuts.jpg',
                    text:'Peanuts webdesign and realisation',
                    label:'Photography',
                    path:'/products/pewr'
                  },
                    {
                    id: "7",
                    src:'images/Kopenhagen.jpg',
                    text:'lowlight photograhpy shoot',
                    label:'Photography',
                    path:'/products/ls1'
                  }

]