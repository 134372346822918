import React, { useState } from 'react';
import Login from '../../components/login/Login';
import { onAuthStateChanged,
  signOut
} from "firebase/auth";
import {auth} from '../../firebase-config';
import PortfolioUpload from '../../components/portfoliosection/PortfolioUpload';
import Read from '../../components/portfoliosection/PortfolioRead';

export default function Dashboard() {

  const [user, setUser] = useState({});

  onAuthStateChanged(auth, (currentUser) => {
    setUser(currentUser);
  });

  const logout = async () => {
    await signOut(auth);
  };

  if(user != null) {
    return(
      <div className='dashboard'>
        <h2>Dashboard</h2>
        <h4> User Logged In: </h4>
            {user?.email}
        <PortfolioUpload/>
        <Read/>
        <button onClick={logout}> Sign Out </button>
      </div>
    );
  }

  return(
    <Login/>
  );

}