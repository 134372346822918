import React, { useState } from 'react';
import {Routes} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import Navbar from './components/navbar/Navbar'
import Home from './pages/Home';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import About from './pages/About';
import Portfolio from './pages/Portfolio';
import SchoolPortfolio from './pages/SchoolPortfolio'; 
import SchoolPortfolioDetail from './components/school/SchoolPortfolioDetail';
import TopicoDetail from './components/school/TopicDetail';
import PortfolioDetail from './pages/PortfolioDetail';
import Login from './components/login/Login';
import Footer from './components/footer/Footer';
import Dashboard from'./pages/Dashboard/Dashboard';
import Page404 from'./pages/page404';
import Labweken from './components/school/Labweken';
import Aframe from './components/school/aframe/Aframe';
import AframeDetail from './components/school/aframe/AframeDetail'
import Arduino from './components/school/arduino/Arduino';
import ArduinoDetail from './components/school/arduino/ArduinoDetail'

function App() {
  const [token, setToken] = useState();
  const instagram = "https://www.instagram.com/yustin.fotografie";
  const facebook = "https://www.facebook.com/yustin.troost/";
  const linkedin = "https://www.linkedin.com/in/yustin-troost-172865152/";
  

  return (
    <>
      <Router>
        <Navbar />
        
        <Routes>
          <Route path="*" element={<Page404/>} />
          <Route path='/' exact element={<Home/>} />
          <Route path='/About' element={<About/>} />
          <Route  exact path='/Portfolio' element={<Portfolio/>} />
          <Route path='/portfolio/:portfoliotId' element={<PortfolioDetail/>}/>
          <Route path='/login' element={<Login/>} />
          <Route path="/dashboard" element={<Dashboard/>}/>

          <Route path="/school" element={<SchoolPortfolio/>}/>
          <Route path='/SchoolPortfolioItems/:SchoolPortfolioId' element={<SchoolPortfolioDetail/>}/>        
          <Route path='/SchoolTopicItems/:schooltopicID' element={<TopicoDetail/>}/>

          <Route path='/SchoolPortfolioItems/labweken' element={<Labweken/>}/>

          <Route path='/SchoolPortfolioItems/aframe' element={<Aframe/>}/>
          <Route path='/AframeItems/:AframeItemsId' element={<AframeDetail/>}/>

          <Route path='/SchoolPortfolioItems/arduino' element={<Arduino/>}/>
          <Route path='/ArduinoItems/:ArduinoItemsId' element={<ArduinoDetail/>}/>
        </Routes>
        <Footer/>
      </Router>
     
    </>
  );
}




export default App;
