import React from 'react';
import { Link } from 'react-router-dom';
import '../App.css';
import '../components/portfoliosection/Portfolio.css';
import SchoolPortfolioItems from '../components/school/SchoolPortfolioItems';
import SchoolTopicItems from '../components/school/SchoolTopicItems';
import Masonry from 'react-masonry-css';
import { Col, Row, Form, Container } from "react-bootstrap";


function SchoolPortfolio(){

   const portfolioItem = SchoolPortfolioItems.map((SchoolPortfolioItems) =>

        <div className='pItem'>
            <Link className='pItemLink' to={`/SchoolPortfolioItems/${SchoolPortfolioItems.id}`}>
            <figure  data-category={SchoolPortfolioItems.label}>
                <img
                className='pItemImg'
                alt='Travel'
                src={SchoolPortfolioItems.src}
                />
            </figure>
            <div className='cards__item__info'>
                <strong><h5 className='cards__item__text'>{SchoolPortfolioItems.text}</h5> </strong>
            </div>
            </Link>
        </div>
        
    );
    const TopicItem = SchoolTopicItems.map((SchoolTopicItems) =>

        <div className='pItem'>
            <Link className='pItemLink' to={`/SchoolTopicItems/${SchoolTopicItems.topicId}`}>
            <figure  data-category={SchoolTopicItems.label}>
                <img
                className='pItemImg'
                alt='Travel'
                src={SchoolTopicItems.src}
                />
            </figure>
            <div className='cards__item__info'>
                <strong><h5 className='cards__item__text'>{SchoolTopicItems.text}</h5> </strong>
            </div>
            </Link>
        </div>
        
    );
    const breakpointColumnsObj = {
        default: 3,
        700: 2,
        500: 1
      };

    return(
        <Container>
            <div className="portfolioContainer">
                <h2>Portfolio</h2>
                <Masonry
                breakpointCols={breakpointColumnsObj}
                className="my-masonry-grid"
                columnClassName="my-masonry-grid_column"> 

                    {portfolioItem}
                    {TopicItem}

                </Masonry>
            </div>
        </Container>
    );
}

export default SchoolPortfolio;