export default [   
                {
                  topicId: "TopicAssignmentXR",
                  src:'#',
                  research:[
                      {
                    soort:'Research',  
                    titel:'Infinte office',      
                    bedrijf: 'Meta ',
                    artikel: 'https://www.oculus.com/blog/facebook-connect-oculus-quest-2-aaa-gaming-partnerships-and-more/ ',
                    video:'https://youtu.be/5_bVkbG1ZCo',
                    src:'',
                    uitleg:'Infinte office is een virtuele werkplek waar je verschillende monitoren kan plaatsen en zo een soort virtueel bureau hebt met zoveel beeldschermen als je zelf wilt. Ook kan je door de camera’s in de Oculus Quest 2 nog steeds je omgeving zien, maar je kan er ook alleen een virtuele omgeving van maken. Logitech is in samenwerking gegaan met meta om zo ook een fysiek toetsenbord en muis te laten werken met deze virtuele werkomgeving.  Uiteindelijk lijkt het er dus een beetje op alsof je een holografische computer bestuurd zoals Iron man.'
                  },
                  {
                    soort:'Research',  
                    titel:'Horizon Workroom', 
                    bedrijf: 'Meta',
                    artikel: 'https://www.oculus.com/blog/workrooms/',
                    video:' https://youtu.be/lgj50IxRrKQ',
                    src:'',
                    uitleg:'Horizon Workrooms is een programma van Meta waar ik erg van onder de indruk ben. Zelf heb ik helaas (nog) geen VR headset maar ik zou dit graag willen testen. Door middel van Horizon Workrooms is het mogelijk om virtueel met anderen te vergaderen. Je kan er onder andere in schetsen, documenten met elkaar delen of virtueel een document mee typen. Ook kun je gewoon je fysieke toetsenbord hiervoor gebruiken. De mensen zonder VR kunnen gewoon meedoen via een videogesprek tot wel 50 personen en in VR kunnen er maximaal 16 personen meedoen. '
                  },    
                  {
                    soort:'Research',  
                    titel:'Google Maps live view', 
                    bedrijf: 'Google',
                    artikel: 'https://blog.google/products/maps/new-sense-direction-live-view/  ',
                    video:'',
                    src:'',
                    uitleg:'De live view functie in Google Maps zorgt ervoor dat aanwijzingen in Google Maps via AR live te zien zijn door je camera heen. Zo zie je een landmark direct op de locatie zelf en staat de pointer van je eind locatie direct op je eind locatie om zo verwarring te voorkomen. Ook hangen de pijltjes om de bocht door te gaan recht boven de bocht zodat je die nooit meer mist. Door deze nieuwe functie is het nog makkelijker om te oriënteren waar je heen moet. '
                  },
                  {
                    soort:'New Concept',  
                    titel:'AR Car window', 
                    bedrijf: '',
                    artikel: '',
                    video:'',
                    src:'../images/Topics/arcar.png',
                    uitleg:'Mijn idee is om van alle ramen in een auto een AR systeem te maken zoals een Google Glasses. Hierdoor wordt het mogelijk om handig informatie zoals snelheid of de route op de voorruit weer te geven zodat de bestuurder niet meer naar iets anders hoeft te kijken en gewoon zijn zicht op de weg kan houden. Ook zou je op de zijramen achterin dingen zoals filmpjes, spelletjes of informatie zoals in Google Maps live view kunnen weergeven om zo de passagiers op de achterbank te kunnen vermaken.'
                  },
                  {
                    soort:'Reflection',  
                    titel:'Reflection', 
                    bedrijf: '',
                    artikel: '',
                    video:'',
                    src:'',
                    uitleg:'Dit onderwerp vind ik zelf erg interessant en ik denk dat dit in de toekomst nog veel meer voorbij gaat zien komen dan dat het nu doet. Vooral het hele gebeuren om de Metaverse vind ik interessant om nog meer over te weten te komen. Ook denk ik dat veel dingen die we nu dagelijks gebruiken we in de toekomst allemaal via VR of AR gaan gebruiken zoals bijvoorbeeld virtuele computer schermen. Van te voren wist ik gelukkig al redelijk veel van dit onderwerp, dus waren de meeste dingen die behandeld werden geen verassing voor mij.  '
                  }
                ],
                  text:'Topic Assignment XR',
                  p:'',
                  src:'images/Topics/vr.jpg',
                  label:'HT',
                },

                {
                  topicId: "TopicassignmentsWearables",
                  src:'images/Topics/wear.jpg',
                  text:'Topic assignments Wearables',
                  p:'',
                  label:'HT',
                  research:[{
                    soort:'Research',  
                    titel:'Smart Rings', 
                    bedrijf: 'NFC OPN Smart Ring',
                    artikel: 'https://www.online-tech-tips.com/gadgets/7-coolest-wearable-electronics-you-need-to-have/ ',
                    video:'',
                    src:'',
                    uitleg:'Dit idee sprak bij aan omdat het erg klein en toch zo handig is. De ring is niet alleen heel handig, maar ook nog fashionable. Door de slimme NFC-chip in deze ring, kun je verschillende pasjes eraan koppelen zoals een Ov Chipkaart en zo kun je gemakkelijk inchecken met je vinger. Ook zou het mogelijk zijn om Apple of Google Pay eraan te koppelen om zo te kunnen betalen met deze ring. Meldingen zou je ook kunnen ontvangen via de ring. Zo zou de ring kunnen trillen en weet je dat je een melding hebt zonder dat je naar je telefoon hoeft te kijken.'
                  },
                  {
                    soort:'Assignment',  
                    titel:'Ringbril', 
                    bedrijf: '',
                    artikel: '',
                    video:'',
                    src:'../images/Topics/bril.jpg',
                    uitleg:'De ringbril is een wearable dat bellen nog makkelijker maakt dan dat het is en er is geen telefoon voor nodig. Het idee is dat je een bril met AR functies erin hebt zoals de Google Glasses. De functies en de interface op de bril blijven wel heel simpel en lijkt een beetje zoals Apple Carplay. De hoofd functie is dat je zou kunnen bellen via je bril. Zo zou je het geluid via de pootjes kunnen horen door trillingen net voor je oor zoals bij een Bone Conduction Hoofdtelefoon. Ook kun je door jouw kijkrichting en het knipperen met je ogen bepaalde dingen doen zoals opnemen of iemand bellen en wordt dit gemeten door een klein cameraatje aan de binnenkant van de bril. Ook zou de bril licht kunnen trillen wanneer je gebeld wordt of een melding krijgt. De microfoon komt onderaan de bril zodat deze het beste geluid uit je mond kan opnemen. '
                  }],
                }, 
                
                {
                  topicId: "TopicassignmentArtificialCreatures",
                  src:'images/Topics/ac.jpg',
                  text:'Topic assignment Artificial Creatures',
                  p:'',
                  label:'HT',
                  research:[{
                    soort:'Homeostasis',  
                    titel:'Koffiezetapparaat 1', 
                    bedrijf: '',
                    artikel: '',
                    video:'',
                    src:'',
                    uitleg:'De belangrijkste benodigdheden om een koffiezetapparaat zijn primaire functie te laten uitvoeren is natuurlijk stroom want zonder dat, kan het apparaat niet aan. Ook heeft een koffiezetapparaat dingen nodig zoals koffiebonen en water om zo uiteindelijk koffie te kunnen produceren. '
                  },{
                  soort:'Homeostasis',  
                  titel:'Koffiezetapparaat 2', 
                  bedrijf: '',
                  artikel: '',
                  video:'',
                  src:'',
                  uitleg:'De stroom van een koffiezetapparaat komt in de meeste gevallen via het stopcontact. Daarbij is wel nodig dat iets of iemand de stekker van het koffiezetapparaat aansluit. Ook is het nodig om de koffiebonen en het water te vervangen wanneer dat op is. Dit zijn allemaal dingen die moeten worden gedaan door een externe stimuli. Voor het melden dat bijvoorbeeld het water op is of dat er kopje onder het koffiezetapparaat moet worden gezet, worden verschillende sensoren gebruikt die daarna weer een melding geven waardoor er hulp wordt gevraagd van een externe stimuli. '
                },{
                  soort:'Homeostasis',  
                  titel:'Koffiezetapparaat 3', 
                  bedrijf: '',
                  artikel: '',
                  video:'',
                  src:'',
                  uitleg:'Het hart van het koffiezetapparaat moet natuurlijk aangestuurd worden door stroom, net zoals dat een menselijk hart zuurstof nodig heeft. Als het koffiezetapparaat dus geen stroom heeft, gebeurt er dus ook niet zoveel. Ook heeft een koffiezetapparaat behoefte aan zijn ingrediënten zoals water en koffie. Zonder dat is het niet mogelijk om zijn primaire functie uit te oefenen, net zoals als dat wij mensen ook eten en drinken nodig hebben. '
                },
                  {
                    soort:'Research',  
                    titel:'Robot als barpersoneel', 
                    bedrijf: 'QBIT Robotics',
                    artikel: 'https://www.reuters.com/article/us-japan-robot-bar/japanese-robot-could-call-last-orders-on-human-bartenders-idUSKBN1ZY17K ',
                    video:'',
                    src:'',
                    uitleg:'Als student, en omdat ik graag een drankje drink in de kroeg, vind ik dit een geniale uitvinding, een robot die bier kan tappen. Het Japanse bedrijf QBIT Robotics heeft een robot ontwikkeld die de hedendaagse barman/vrouw zou kunnen vervangen. De robot maakt in 40 seconden een perfect biertje en heeft maar één minuut nodig om een lekkere cocktail voor je te serveren. “It uses four cameras to monitors customers to analyze their expressions with artificial intelligence (AI) software.” (Kelly, 2020) Door zijn artificial intelligence begint het bijna te lijken op echt bar personeel door al zijn functies. Deze robot zit nog in de testfase, maar hij zou zelfs via een chat een praatje met je kunnen maken over bijvoorbeeld het weer. Zo zou je ook de gezelligheid van een barman/vrouw niet missen met deze robot. Deze robot is ontwikkeld om het probleem in Japan om bar personeel te vinden op te lossen. Dit tekort komt doordat dat het inwoners aantal van Japan steeds lager wordt en door dit soort robots te ontwikkelen, zou het personeelstekort in veel sectoren kunnen worden opgelost. Deze robot heeft wel een groot prijskaartje van maar liefst 9 miljoen yen (63.297,00 euro). Voor dit bedrag zou je ook menselijk personeel voor 3 jaar kunnen betalen.'
                  },
                  {
                    soort:'Reflectie',  
                    titel:'Reflectie', 
                    bedrijf: 'New Playful Interaction Concept',
                    artikel: '',
                    video:'',
                    src:'',
                    uitleg:'Dit onderwerp vind ik zelf erg interessant, vooral omdat dit toekomst wordt. Alles kan uiteindelijk worden vervangen door een robot, wat  uiteindelijk onze taken steeds makkelijker maakt. Zo hoeven we ons gras niet meer zelf te maaien, ons huis niet meer zelf te stofzuigen en wordt straks je drinken geserveerd door een robot. Ook het idee dat robots/ machines steeds slimmer worden vind ik zelf heel interessant. Hoelang duurt het bijvoorbeeld nog voordat robots zichzelf kunnen gaan ontwikkelen en uit zichzelf slimmer worden en zo misschien wel slimmer dan de mens. Gaat het in de toekomst dan toch nog op Star Wars lijken waar robots en mensen samen leven of blijft de mens toch nog de baas over de robots? Dit onderwerp ben ik uiteindelijk toch interessanter gaan vinden dan dat ik dacht en ook ging dit onderwerp dieper dan ik verwacht had. Ik was vooral onder de indruk van wat er op dit moment al kan met artificial creatures en dat is zeker meer dan ik had gedacht. Zo wist ik zelf natuurlijk wel af van bijvoorbeeld een robotmaaier, maar ik wist niet dat de robot hond van Boston Dynamics zo complex was. Tijdens de labweken ben ik ook aan de slag gegaan met Artificial creatures en hebben we er uiteindelijk zelf een gebouwd wat een erg leuk en leerzaam proces was. '
                  },]
                },

                {
                topicId: "TopicAssignmentPlayfulInteraction2022",
                src:'images/Topics/pi.jpeg',
                text:'Topic Assignment Playful Interaction 2022',
                p:'',
                label:'HT',
                research:[{
                  soort:'Concept',  
                  titel:'New Playful Interaction Concept', 
                  bedrijf: '',
                  artikel: '',
                  video:'',
                  src:'',
                  uitleg:'Op school zit je de hele dag achter je bureau en de meesten zitten nog altijd lekker onderuit gezakt op de stoelen, maar gaan wel achteraf klagen over rugklachten. Mijn idee is om staan/zit combinatie bureaus in de lokalen neer te zetten die standaard na 20 minuten zitten, omhoog gaan zodat je wel moet staan. Hoe langer je dan blijft staan, hoe langer je daarna weer kan blijven zitten. Dit zorgt ervoor dat iedereen een beetje actief blijft tijdens de af en toe saaie lessen. Ook zou je extra zit minuten kunnen verdienen door bijvoorbeeld de trap de nemen. Dit wordt allemaal gekoppeld aan je schoolkaart. Zo kun je in- en uitchecken bij een tafel om zo gebruik te maken van een laag bureau om zittend te kunnen werken. Als je je schoolkaart bij je draagt, wordt dat ook gemeten als je het trappenhuis in- en uitloopt per verdieping om zo nog meer zit minuten te verdienen. Door studenten te motiveren om zo meer te bewegen en actief aan het werk te gaan, zorgt het ervoor dat de hersenen actief blijven en zo blijven de studenten ook nog eens fit. Ook voorkomt het rugklachten op een latere leeftijd volgens verschillende onderzoeken naar staan bureaus. '
                },
                {
                  soort:'Research',  
                  titel:'Hollebolle Gijs', 
                  bedrijf: 'Efteling (Anton Piek)',
                  artikel: 'https://www.pretparken.nl/holle-bolle-gijs/ ',
                  video:'',
                  src:'',
                  uitleg:'Misschien wel een van de bekendste voorbeelden van playfull interaction in Nederland is Hollebolle Gijs in de Efteling. De papierbak in de Efteling spreekt de hele tijd hetzelfde zinnetje “papier hier” uit en zodra je je papiertje erin gooit, bedankt hij je. Toen in 1952 de Efteling open is gegaan, was er een probleem bij de eetkraampjes omdat iedereen overal zijn servetjes liet rondslingeren “Anton Pieck had inmiddels kleinduimpje prullenbakken ontworpen, maar de uitdaging was, om het in de prullenbak gooien van papier leuk te maken.” (Holle bolle gijs, sd) Doordat kinderen en volwassen beloond werden doordat ze hun afval gewoon in de prullenbak gooiden, was het probleem van rondzwervende servetjes gauw verdwenen. Wel kwam het volgende probleem en dat was dat kinderen het zo leuk vonden dat er ook platen en andere dingen in werden gegoord die daar niet hoorden. Het is dus een gestructureerd systeem wat mensen beloond voor het weggooien van hun afval. Met een bepaalde interval spreekt de prullenbak “papier hier“ uit als er mensen in de buurt zijn van de prullenbak. Door een sensor in de mond van Hollebolle Gijs, wordt er gescand of er iets in wordt gegooid en nadat de sensor dat gemeten heeft, bedankt hij daar de gebruiker voor. De acties van Hollebolle Gijs zijn en blijven heel simpel, maar het werkt wel.'
                },
                {
                  soort:'Reflectie',  
                  titel:'Reflectie', 
                  bedrijf: 'New Playful Interaction Concept',
                  artikel: '',
                  video:'',
                  src:'',
                  uitleg:'Dit onderwerp vind ik zelf wel het minste onderwerp van de 4 onderwerpen voor dit vak. Ik vind zelf dat het allemaal uitvindingen zijn om eigenlijk basisnormen en waarden waar niet iedereen zich aan houdt te belonen. Wel vond ik het leuk wat verschillende mensen er allemaal mee hebben en dat het wel een leuke uitkomst heeft, maar vind ik zelf de reden waarom sommige dingen ontworpen zijn erg triest. Bijvoorbeeld Hollebolle Gijs, het is toch doodnormaal om je afval in de prullenbak te gooien?! Toch moet het dan beloond worden want anders doen mensen het niet. Wel vind ik de technologie erachter interessant en de werking ervan, maar dat zijn meestal dingen die al ergens anders voor zijn gebruikt. Ik denk dat de meeste toepassingen van playfull interactie tijdelijk zijn om iets te vieren op een bepaalde plaats, dus ik denk dat ik het zeker in de toekomst terugkomt maar niet vaker dan dat het nu gebuikt wordt. Wel zullen er verbeteringen voorkomen voor bepaalde interacties, maar ik denk niet dat een cruciaal of belangrijk wordt in de toekomst zoals artifical creatures. Het onderwerp kwam wel overheen met mijn verwachtingen hiervan, maar ik ben er nog steeds over eens dan het geen cruciale of baanbrekende uitvinding is.'
                },]
                
              }


]

