import React from 'react';
import '../App.css';
import Cards from '../components/card3d/Cards';
import HeroSection from '../components/herosection/HeroSection';
import InfoSection from '../components/InfoSection';  
import AppDesign from '../components/appDesign/AppDesign';
import WebDesign from '../components/webDesign/WebDesign';
import { about, about2, facts } from '../components/InfoSection/Data';
import Cards1 from '../components/cards/Cards1';


function Home() {
  return (
    <>
      <HeroSection />
      <InfoSection {...about}/>
      <AppDesign/>
      <InfoSection {...about2}/>
      <WebDesign/>
      {/* <Cards /> */}
      {/* <InfoSection {...facts}/> */}
      <Cards1 />
     
    </>
  );
}

export default Home;
