// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {getAuth} from 'firebase/auth';
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyA0r7WiVVGn7vY4e2FxTG9LlS696p0zkBo",
  authDomain: "reactjsportfolio-yustin.firebaseapp.com",
  databaseURL: "https://reactjsportfolio-yustin-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "reactjsportfolio-yustin",
  storageBucket: "reactjsportfolio-yustin.appspot.com",
  messagingSenderId: "567016268631",
  appId: "1:567016268631:web:6be645c9003a9ed8a55412",
  measurementId: "G-JM550DNB02"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const auth = getAuth(app);
export const db = getFirestore(app);