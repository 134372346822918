export default [
  
                {
                  id: "1",
                  src:'../../images/aframe/1/1.png',
                  text:'Opdracht 1',
                  p:'',
                  label:'HT',
                  gallery:[
                    {
                      original: '../../images/aframe/1/1.png',
                      thumbnail: '../../images/aframe/1/1.png'
                    }]
                },
                {
                    id: "2",
                    src:'../../images/aframe/2/2.png',
                    text:'Opdracht 2',
                    p:'',
                    label:'HT',
                    gallery:[
                      {
                        original: '../../images/aframe/2/2.png',
                        thumbnail: '../../images/aframe/2/2.png'
                      },
                      {
                        original: '../../images/aframe/2/3.png',
                        thumbnail: '../../images/aframe/2/3.png'
                      },
                      {
                        original: '../../images/aframe/2/4.png',
                        thumbnail: '../../images/aframe/2/4.png'
                      },
                      {
                        original: '../../images/aframe/2/5.png',
                        thumbnail: '../../images/aframe/2/5.png'
                      },
                      {
                        original: '../../images/aframe/2/6.png',
                        thumbnail: '../../images/aframe/2/6.png'
                      },
                      {
                        original: '../../images/aframe/2/7.png',
                        thumbnail: '../../images/aframe/2/7.png'
                      },
                      {
                        original: '../../images/aframe/2/8.png',
                        thumbnail: '../../images/aframe/2/8.png'
                      },

                    ]
                  },
                  {
                    id: "3",
                    src:'../../images/aframe/3/1.png',
                    text:'Opdracht 3',
                    p:'',
                    label:'HT',
                    gallery:[
                        {
                            original: '../../images/aframe/3/2.png',
                            thumbnail: '../../images/aframe/3/2.png'
                          },
                          {
                            original: '../../images/aframe/3/3.png',
                            thumbnail: '../../images/aframe/3/3.png'
                          },
                          {
                            original: '../../images/aframe/3/4.png',
                            thumbnail: '../../images/aframe/3/4.png'
                          },
                          {
                            original: '../../images/aframe/3/5.png',
                            thumbnail: '../../images/aframe/3/5.png'
                          },
                          {
                            original: '../../images/aframe/3/6.png',
                            thumbnail: '../../images/aframe/3/6.png'
                          },]
                  },
                  {
                    id: "4",
                    src:'../../images/aframe/4/1.png',
                    text:'Opdracht 4',
                    p:'',
                    label:'HT',
                    gallery:[
                        {
                            original: '../../images/aframe/4/1.png',
                            thumbnail: '../../images/aframe/4/1.png'
                          },
                          {
                            original: '../../images/aframe/4/2.png',
                            thumbnail: '../../images/aframe/4/2.png'
                          },

                          {
                            original: '../../images/aframe/4/3.png',
                            thumbnail: '../../images/aframe/4/3.png'
                          },
                          {
                            original: '../../images/aframe/4/4.png',
                            thumbnail: '../../images/aframe/4/4.png'
                          },
                          {
                            original: '../../images/aframe/4/5.png',
                            thumbnail: '../../images/aframe/4/5.png'
                          },
                          {
                            original: '../../images/aframe/4/6.png',
                            thumbnail: '../../images/aframe/4/6.png'
                          },
                          {
                            original: '../../images/aframe/4/7.png',
                            thumbnail: '../../images/aframe/4/7.png'
                          },]
                  },
                  {
                    id: "5",
                    src:'../../images/aframe/5/2.png',
                    text:'Opdracht 5',
                    p:'',
                    label:'HT',
                    gallery:[
                        {
                            original: '../../images/aframe/5/1.png',
                            thumbnail: '../../images/aframe/5/1.png'
                          },
                        {
                            original: '../../images/aframe/5/2.png',
                            thumbnail: '../../images/aframe/5/2.png'
                          },
                          {
                            original: '../../images/aframe/5/3.png',
                            thumbnail: '../../images/aframe/5/3.png'
                          },
                          {
                            original: '../../images/aframe/5/4.png',
                            thumbnail: '../../images/aframe/5/4.png'
                          },
                          {
                            original: '../../images/aframe/5/5.png',
                            thumbnail: '../../images/aframe/5/5.png'
                          },
                         ]
                  },
                  {
                    id: "6",
                    src:'../../images/aframe/6/1.png',
                    text:'Opdracht 6',
                    p:'',
                    label:'HT',
                    gallery:[
                      {
                        original: '../../images/aframe/6/1.png',
                        thumbnail: '../../images/aframe/6/1.png'
                      },
                      {
                        original: '../../images/aframe/6/1.png',
                        thumbnail: '../../images/aframe/6/1.png'
                      },
                      {
                        original: '../../images/aframe/6/1.png',
                        thumbnail: '../../images/aframe/6/1.png'
                      }]
                  },
        
]

