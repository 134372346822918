import React from 'react';
import '../../App.css';
import './WebDesign.css';
import { Col, Row, Form, Container } from "react-bootstrap";



function WebDesign(){




    return(
        <div className='whiteBG'>
        <Container >
            <Row className=' appDesign'>
            <Col xs={12} md={4} className="appText">
                    <Col xs={6} md={12}>
                        <div className='topline'><a>Portfolio</a></div>
                        <h2 className='appTitle'>WebDesign</h2>
                    </Col>
                    <Col xs={6} md={12} className="appSubText">
                        <p>Web Ontwikkeling/ - design:<br></br>
                        <i>Peanuts Food & Coaching</i> </p>
                        <p>Tools: <br></br>
                        <i>Wordpress, Adobe XD</i></p>
                    </Col>
            </Col>
            <Col xs={12} md={8}>
                <img
                    className='appImg'
                    alt='appDesign'
                    src='images/peanuts.png'
                    />
                </Col>
               
            </Row>
        </Container>
        </div>
    );
}

export default WebDesign;