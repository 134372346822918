import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';
import { Button } from '../button/Button';
import { onAuthStateChanged,
  signOut
} from "firebase/auth";
import {auth} from '../../firebase-config';

function Navbar() {
  const [user, setUser] = useState({});
  
  onAuthStateChanged(auth, (currentUser) => {
    setUser(currentUser);
  });

  const logout = async () => {
    await signOut(auth);
  };

  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const showButton = () => {
    if (window.innerWidth <= 960) {
      setButton(false);
    } else {
      setButton(true);
    }
  };

  useEffect(() => {
    showButton();
  }, []);

  window.addEventListener('resize', showButton);
  if(user != null) {
    return (
      <>
        <div className='navbar'>
          <div className='navbar-container'>
            <Link to='/' className='navbar-logo viking' onClick={closeMobileMenu}>
             Yustin Troost
              <i class='fab fa-typo3' />
            </Link>
            <div className='menu-icon' onClick={handleClick}>
              <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
            </div>
            <ul className={click ? 'nav-menu active' : 'nav-menu'}>
              <li className='nav-item'>
                <Link to='/' className='nav-links' onClick={closeMobileMenu}>
                  Home
                </Link>
              </li>
              <li className='nav-item'>
                <Link
                  to='/Portfolio'
                  className='nav-links'
                  onClick={closeMobileMenu}
                >
                  Portfolio
                </Link>
              </li>
              <li className='nav-item'>
                <Link
                  to='/School'
                  className='nav-links'
                  onClick={closeMobileMenu}
                >
                  School
                </Link>
              </li>
  
              <li>
                <Link 
                to='/dashboard'
                  className='nav-links-mobile'
                  onClick={closeMobileMenu}
                >
                 Dashboard
                </Link>
              </li>
          
              <li className='nav-item'>
              {button && 
              <Link
              to='/dashboard'>
                
              <Button buttonStyle='btn--outline'>Dashboard</Button>
              </Link>
              }
              </li>
            </ul>
          </div>
        </div>
      </>
    );
    }
    return (
      <>
        <div className='navbar'>
          <div className='navbar-container'>
            <Link to='/' className='navbar-logo viking' onClick={closeMobileMenu}>
            Yustin Troost
              <i class='fab fa-typo3' />
            </Link>
            <div className='menu-icon' onClick={handleClick}>
              <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
            </div>
            <ul className={click ? 'nav-menu active' : 'nav-menu'}>
              <li className='nav-item'>
                <Link to='/' className='nav-links' onClick={closeMobileMenu}>
                  Home
                </Link>
              </li>
              <li className='nav-item'>
                <Link
                  to='/Portfolio'
                  className='nav-links'
                  onClick={closeMobileMenu}
                >
                  Portfolio
                </Link>
              </li>
              <li className='nav-item'>
                <Link
                  to='/school'
                  className='nav-links'
                  onClick={closeMobileMenu}
                >
                  School
                </Link>
              </li>

              <li className='nav-item'>
                <Link
                  to='/login'
                  className='nav-links-mobile'
                  onClick={closeMobileMenu}
                >
                  Login
                </Link>
              </li>
              <li className='nav-item'>
              {button && 
              <Link
              to='/dashboard'>
              <Button buttonStyle='btn--outline'>Login</Button>
              </Link>
              }
             </li>
            </ul>
          </div>
        </div>
      </>
    );
  

}

export default Navbar;
