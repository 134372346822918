export default [
  
    {
      id: "3",
      src:'../../images/arduino/3/1.png',
      text:'Opdracht 3',
      p:'',
      label:'HT',
      gallery:[
        {
          original: '../../images/arduino/3/1.png',
          thumbnail: '../../images/arduino/3/1.png'
        },]
    },
    {
        id: "4",
        src:'../../images/arduino/4/c.png',
        text:'Opdracht 4',
        p:'',
        label:'HT',
        gallery:[
          {
            original: '../../images/arduino/4/a.png',
            thumbnail: '../../images/arduino/4/a.png'
          },
          {
            original: '../../images/arduino/4/c.png',
            thumbnail: '../../images/arduino/4/c.png'
          },
          {
            original: '../../images/arduino/4/c.MOV',
            thumbnail: '../../images/arduino/4/c.png'
          },
        ]
      },   {
        id: "6",
        src:'../../images/arduino/6/c.png',
        text:'Opdracht 6',
        p:'',
        label:'HT',
        gallery:[
          {
            original: '../../images/arduino/6/c.png',
            thumbnail: '../../images/arduino/6/c.png'
          },
          {
            original: '.../../images/arduino/6/c.MOV',
            thumbnail: '../../images/arduino/6/c.png'
          },
        ]
      },
      {
        id: "7",
        src:'../../images/arduino/7/a.png',
        text:'Opdracht 7',
        p:'',
        label:'HT',
        gallery:[
          {
            original: '../../images/arduino/7/a.png',
            thumbnail: '../../images/arduino/7/a.png'
          },
          {
            original: '.../../images/arduino/7/a.MOV',
            thumbnail: '../../images/arduino/7/a.png'
          },
        ]
      },
      {
        id: "8",
        src:'../../images/arduino/8/a.png',
        text:'Opdracht 8',
        p:'',
        label:'HT',
        gallery:[
          {
            original: '../../images/arduino/8/a.png',
            thumbnail: '../../images/arduino/8/a.png'
          },
          {
            original: '../../images/arduino/8/a.MOV',
            thumbnail: '../../images/arduino/8/a.png'
          },]
      },
      {
        id: "9",
        src:'../../images/arduino/9/9.JPG',
        text:'Opdracht 9',
        p:'',
        label:'HT',
        gallery:[
            {
                original: '../../images/arduino/9/9.jpg',
                thumbnail: '../../images/arduino/9/9.jpg'
              },
          {
            original: '../../images/arduino/9/9.MOV',
            thumbnail: '../../images/arduino/9/9.jpg'
          },]
      },
      {
        id: "11",
        src:'../../images/arduino/11/11.jpg',
        text:'Opdracht 11',
        p:'',
        label:'HT',
        gallery:[
          {
            original: '../../images/arduino/11/11.jpg',
            thumbnail: '../../images/arduino/11/11.jpg'
          },
          {
            original: '.../../images/arduino/11/11.MOV',
            thumbnail: '../../images/arduino/11/11.jpg'
          },
        ]
      },
      {
        id: "12",
        src:'../../images/arduino/12/1.png',
        text:'Opdracht 12',
        p:'',
        label:'HT',
        gallery:[
          {
            original: '../../images/arduino/12/1.png',
            thumbnail: '../../images/arduino/12/1.png'
          },
          {
            original: '.../../images/arduino/12/2.png',
            thumbnail: '../../images/arduino/12/2.png'
          },
          {
            original: '../../images/arduino/12/12.MOV',
            thumbnail: '../../images/arduino/12/1.png'
          },
        ]
      },
      {
        id: "13",
        src:'../../images/arduino/13/13.png',
        text:'Opdracht 13',
        p:'',
        label:'HT',
        gallery:[
          {
            original: '../../images/arduino/13/13.png',
            thumbnail: '../../images/arduino/13/13.png'
          },
          {
            original: '../../images/arduino/13/13.MOV',
            thumbnail: '../../images/arduino/13/13.png'
          },]
      },
      {
        id: "14",
        src:'../../images/arduino/14/14.png',
        text:'Opdracht 14',
        p:'',
        label:'HT',
        gallery:[
          {
            original: '../../images/arduino/14/14.png',
            thumbnail: '../../images/arduino/14/14.png'
          },
          {
            original: '../../images/arduino/14/14.MOV',
            thumbnail: '../../images/arduino/14/14.png'
          },]
      },
      {
        id: "15",
        src:'../../images/arduino/15/15.png',
        text:'Opdracht 15',
        p:'',
        label:'HT',
        gallery:[
          {
            original: '../../images/arduino/15/15.png',
            thumbnail: '../../images/arduino/15/15.png'
          },
          {
            original: '../../images/arduino/15/15.MOV',
            thumbnail: '../../images/arduino/15/15.png'
          },]
      },
      {
        id: "16",
        src:'../../images/arduino/16/16.png',
        text:'Opdracht 16',
        p:'',
        label:'HT',
        gallery:[
          {
            original: '../../images/arduino/16/16.png',
            thumbnail: '../../images/arduino/16/16.png'
          },
          {
            original: '../../images/arduino/16/16.MOV',
            thumbnail: '../../images/arduino/16/16.png'
          },
        ]
      }
]

