import React from 'react';
import '../../App.css';
import {db} from '../../firebase-config';
import {useState} from 'react';

const PortfolioUpload = () => {
	const [Id, SetId] = useState("");
	const [Label , SetLabel] = useState("");
	const [Text , SetText] = useState("");
	const sub = (e) => {
		e.preventDefault();
		
		// Add data to the store
		db.collection("PortfolioItems").add({
			id: Id,
			label: Label,
			text: Text
		})
		.then((docRef) => {
			alert("Data Successfully Submitted");
		})
		.catch((error) => {
			console.error("Error adding document: ", error);
		});
	}

  
	return (
		<div>
			<center>
				<form style={{marginTop:"200px" }}
				onSubmit={(event) => {sub(event)}}>
					<input type="number" placeholder="id"
					onChange={(e)=>{SetId(e.target.value)}} />
					<br/><br/>
					<input type="text" placeholder="label"
					onChange={(e)=>{SetLabel(e.target.value)}}/>
					<br/><br/>
					<input type="text" placeholder="tekst"
					onChange={(e)=>{SetText(e.target.value)}}/>
					<br/><br/>
					<button type="submit">Submit</button>
				</form>
			</center>
		</div>
	);
}

export default PortfolioUpload;

