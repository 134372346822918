import React from "react"
import {useParams} from "react-router-dom"
import SchoolTopicItems from "./SchoolTopicItems"
import { Col, Row, Form, Container } from "react-bootstrap";
import "./SchoolPortfolioDetail.css";

function PortfolioDetail() {
    const {schooltopicID} = useParams()
    const thisPortfolio = SchoolTopicItems.find(SchoolTopicItems => SchoolTopicItems.topicId === schooltopicID)
    

    const research = thisPortfolio.research.map((thisPortfolioResearch) =>
    
    <Container className='topicCon'>
        <Row>
            <Col xs={6} md={12}>
            <div className='blue'><a>{thisPortfolioResearch.soort}</a></div>
            <h2 >{thisPortfolioResearch.titel}</h2>
            </Col>
        </Row>
        <Row className=''>
            <Col xs={12} md={4} className="">
                    <Col xs={6} md={12} className="">
                    {thisPortfolioResearch.bedrijf.length > 0 && 
                        <p>Bedrijf:<br></br>
                        <i>{thisPortfolioResearch.bedrijf}</i></p>
                    }
                    {thisPortfolioResearch.video.length > 0 && 
                        <p>Video:<br></br>
                        <i>{thisPortfolioResearch.video}</i></p>
                    }
                    {thisPortfolioResearch.artikel.length > 0 && 
                        <p>Artikel:<br></br>
                        <i>{thisPortfolioResearch.artikel}</i></p>
                    }
                    {thisPortfolioResearch.src.length > 0 && 
                        <p>Afbeelding:<br></br>
                        <img className="Topicimg" src={thisPortfolioResearch.src}/></p>
                    }
                    </Col>
            </Col>
            <Col xs={12} md={8} >
                <p> {thisPortfolioResearch.uitleg}</p>
            </Col>
        </Row>
    </Container>
    );

    return (<div>{research}</div>
            
            )
}

export default PortfolioDetail