import React from "react"
import {useParams} from "react-router-dom"
import SchoolPortfolioItems from "./SchoolPortfolioItems"
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
import { Col, Row, Form, Container } from "react-bootstrap";
import "./SchoolPortfolioDetail.css";

function PortfolioDetail() {
    const {SchoolPortfolioId} = useParams()
    const thisPortfolio = SchoolPortfolioItems.find(SchoolPortfolioItems => SchoolPortfolioItems.id === SchoolPortfolioId)
    
    return (
        <Container className='schoolportfolio'>
           <Row >
                <Col xs={12} md={4} className="">
                    <ImageGallery items={thisPortfolio.gallery} />
               </Col>
                <Col xs={12} md={8} className="">
                    <h2>{thisPortfolio.text}</h2>
                    <p>{thisPortfolio.p}</p>
               </Col>
           </Row>
       </Container>
    )
}

export default PortfolioDetail