import React from 'react';
import '../../App.css';
import './AppDesign.css';
import { Col, Row, Form, Container } from "react-bootstrap";



function AppDesign(){




    return(
        <div className='whiteBG'>
        <Container >
            <Row className=' appDesign'>
                <Col xs={12} md={4} className="appText">
                    <Col xs={12} md={12}>
                        <div className='topline'><a>Portfolio</a></div>
                        <h2 className='appTitle'>App Design</h2>
                    </Col>
                    <Col xs={12} md={12} className="appSubText">
                        <p>App Design:<br></br><i> Rainbow Den Haag</i></p>
                        <p>Tools:<br></br> 
                        <i> Adobe Xd, Adobe Photoshop</i></p>
                    </Col>
                </Col>
                <Col xs={12} md={8}>
                <img
                    className='appImg'
                    alt='appDesign'
                    src='images/rbdh.png'
                    />
                </Col>
            </Row>
        </Container>
        </div>
    );
}

export default AppDesign;