import React from 'react'
import { Button } from '../button/Button';
import { Column2, Img, ImgWrap, InfoContainer, InfoRow, InfoWrapper, TopLine, TextWrapper, Heading, Subtitle, BtnWrap, Column1,  } from './InfoElements';
import { Link } from 'react-router-dom';
import { Container } from 'react-bootstrap';


const InfoSection = ({lightBg, id, imgStart, topLine, lightText, headLine, darkText, buttonLabel, img, alt, description, btnStyle, btnTo}) => {
    return (
        <>
        <div className='whiteBG'>
            <Container>
                <InfoContainer lightBg={lightBg} id={id}>
                    <InfoWrapper>
                        <InfoRow imgStart={imgStart}>
                            <Column1>
                            <TextWrapper>  
                                <TopLine><a>{topLine}</a></TopLine>
                                <Heading lightText={lightText}><h2>{headLine}</h2></Heading>
                                <Subtitle darkText={darkText}><p>{description}</p></Subtitle>
                                <BtnWrap>
                                    <Link  to={btnTo}>
                                        <Button buttonStyle={btnStyle} >{buttonLabel}</Button>
                                    </Link>
                                </BtnWrap>
                            </TextWrapper>
                            </Column1>
                            <Column2>
                                <ImgWrap>
                                    <Img  src={img} alt={alt}/>
                                </ImgWrap>
                            </Column2>
                        </InfoRow>
                    </InfoWrapper>
                </InfoContainer>
            </Container>
        </div>
        </>
    )
}

export default InfoSection
