// Import Firestore database
import React from 'react';
import '../../App.css';
import {db} from '../../firebase-config';
import {useState} from 'react';

const Read = () => {

	const [info , setInfo] = useState([]);

	// Start the fetch operation as soon as
	// the page loads
	window.addEventListener('load', () => {
		Fetchdata();
	});

	// Fetch the required data using the get() method
	const Fetchdata = ()=>{
		db.collection("PortfolioItems").get().then((querySnapshot) => {
			
			// Loop through the data and store
			// it in array to display
			querySnapshot.forEach(element => {
				var data = element.data();
				setInfo(arr => [...arr , data]);
				
			});
		})
	}
	
	// Display the result on the page
	return (
		<div>
			<center>
			<h2>Student Details</h2>
			</center>
		
		{
			info.map((data) => (
			<Frame course={data.id}
				name={data.label}
				age={data.text}/>
			))
		}
		</div>

	);
}

// Define how each display entry will be structured
const Frame = ({id , label , text}) => {
	console.log(id + " " + label + " " + text);
	return (
		<center>
			<div className="div">
				
			<p>NAME : {id}</p>

							
			<p>Age : {label}</p>

							
			<p>Course : {text}</p>

						</div>
					</center>
				);
			}

export default Read;

