import React from 'react';
import { Link } from 'react-router-dom';
import '../../../App.css';
import '../../../components/portfoliosection/Portfolio.css';
import AframeItems from './AframeItems';
import Masonry from 'react-masonry-css';
import { Col, Row, Form, Container } from "react-bootstrap";


function aframe(){

   const portfolioItem = AframeItems.map((AframeItems) =>

        <div className='pItem'>
            <Link className='pItemLink' to={`/AframeItems/${AframeItems.id}`}>
            <figure  data-category={AframeItems.label}>
                <img
                className='pItemImg'
                alt='Travel'
                src={AframeItems.src}
                />
            </figure>
            <div className='cards__item__info'>
                <strong><h5 className='cards__item__text'>{AframeItems.text}</h5> </strong>
            </div>
            </Link>
        </div>
        
    );
    const breakpointColumnsObj = {
        default: 3,
        700: 2,
        500: 1
      };

    return(
        <Container>
            <div className="portfolioContainer">
                <h2>Aframe</h2>
                <Masonry
                breakpointCols={breakpointColumnsObj}
                className="my-masonry-grid"
                columnClassName="my-masonry-grid_column"> 

                    {portfolioItem}
                </Masonry>
            </div>
        </Container>
    );
}

export default aframe;