import React from 'react';
import { Link } from 'react-router-dom';
import '../../App.css';
import { Button } from '../button/Button';
import './HeroSection.css';

function HeroSection() {
  return (
    <div className='hero-container'>
      <video src='/videos/Black.mp4' autoPlay loop muted />
      <h1>I REALIZE YOUR IDEAS</h1>
      <p className="heroSubTekst"> <i>Welkom! ik ben Yustin Troost, een grafisch vormgever, maar ik focus mij vooral op fotograferen en het ontwerpen/bouwen van websites.</i></p>
      <div className='hero-btns'>
        <Link to="portfolio">
          <Button
            className='btns'
            buttonStyle='btn--outline'
            buttonSize='btn--large'
            
          >
            MY PROJECTS
          </Button>
        </Link>
        
          <a href="https://www.instagram.com/yustin.fotografie">
            <Button
            className='btns'
            buttonStyle='btn--primary'
            buttonSize='btn--large'
            
            onClick={console.log('hey')}
            >
            PHOTOGRAPHY <i className='far fa-camera' />
            
            </Button>
          </a>
      </div>
    </div>
  );
}

export default HeroSection;
