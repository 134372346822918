import React from 'react';
import './Cards.css';
import CardItem from './CardItem';

function Cards1() {
  return (
    <div className='cards'>
        <div className='topline'>Portfolio</div>
      <h2>Some of my latest projects</h2>
      <div className='cards__container'>
        <div className='cards__wrapper'>
          <ul className='cards__items'>
            <CardItem
              src='images/Energy21.jpg'
              text='E21 promo video'
              label='vidoe edit'
              path='/sign-up'
            />
            <CardItem
                src='images/8.jpg'
                text='Rainbow Den Haag mobile design'
                label='Mobile design'
                path='/sign-up'
              />
             <CardItem
              src='images/peanuts.jpg'
              text='Peanuts food & coaching web design en realisation'
              label='Webdesign'
              path='/services'
            />
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Cards1;
