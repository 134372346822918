import React from "react"
import {useParams} from "react-router-dom"
import PortfolioItems from "../components/portfoliosection/PortfolioItems"

function PortfolioDetail() {
    const {portfoliotId} = useParams()
    const thisPortfolio = PortfolioItems.find(PortfolioItem => PortfolioItem.id === portfoliotId)
    
    return (
        <div>
            <h1>{thisPortfolio.text}</h1>
            <p>Id: {thisPortfolio.id}</p>
        </div>
    )
}

export default PortfolioDetail