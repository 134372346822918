import React from "react"
import {useParams} from "react-router-dom"
import SchoolPortfolioItems from "./SchoolPortfolioItems"
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
import { Col, Row, Form, Container } from "react-bootstrap";
import "./SchoolPortfolioDetail.css";

function Labweken() {
    const dag1 = [
        {
          original: '../images/labweken/dag1/1.jpg',
          thumbnail: '../images/labweken/dag1/1.jpg',
        },
        {
          original: '../images/labweken/dag1/2.jpg',
          thumbnail: '../images/labweken/dag1/2.jpg',
        },
        {
          original: '../images/labweken/dag1/3.jpg',
          thumbnail: '../images/labweken/dag1/3.jpg',
        },
        {
            original: '../images/labweken/dag1/4.jpg',
            thumbnail: '../images/labweken/dag1/4.jpg',
          },
        //   {
        //     original: '../images/labweken/dag1/1.mov',
        //     thumbnail: '../images/labweken/dag1/1.mov',
        //   },
        //   {
        //     original: '../images/labweken/dag1/2.mov',
        //     thumbnail: '../images/labweken/dag1/2.mov',
        //   },
        //   {
        //     original: '../images/labweken/dag1/3.mov',
        //     thumbnail: '../images/labweken/dag1/3.mov',
        //   },
        //   {
        //     original: '../images/labweken/dag1/4.mov',
        //      thumbnail: '../images/labweken/dag1/4.mov',
        //   },
      ];

      const dag2 = [
        {
          original: '../images/labweken/dag2/1.jpg',
          thumbnail: '../images/labweken/dag2/1.jpg',
        },
        {
          original: '../images/labweken/dag2/2.jpg',
          thumbnail: '../images/labweken/dag2/2.jpg',
        },
        {
          original: '../images/labweken/dag2/3.jpg',
          thumbnail: '../images/labweken/dag2/3.jpg',
        },
        {
            original: '../images/labweken/dag2/4.jpg',
            thumbnail: '../images/labweken/dag2/4.jpg',
          },
        //   {
        //     original: '../images/labweken/dag2/1.mp4',
        //     thumbnail: '../images/labweken/dag2/1.mmp4',
        //   },
        //   {
        //     original: '../images/labweken/dag2/2.mp4',
        //     thumbnail: '../images/labweken/dag2/2.mp4',
        //   },
        //   {
        //     original: '../images/labweken/dag2/3.mp4',
        //     thumbnail: '../images/labweken/dag2/3.mp4',
        //   },
        //   {
        //     original: '../images/labweken/dag2/4.mp4',
        //      thumbnail: '../images/labweken/dag2/4.mp4',
        //   },
         //   {
        //     original: '../images/labweken/dag2/5.mov',
        //      thumbnail: '../images/labweken/dag2/5.mov',
        //   },
      ];

      const dag3 = [
        {
          original: '../images/labweken/dag3/1.jpeg',
          thumbnail: '../images/labweken/dag3/1.jpeg',
        },
        //   {
        //     original: '../images/labweken/dag3/1.mp4',
        //     thumbnail: '../images/labweken/dag3/1.mmp4',
        //   },
      ];

      const dag4 = [
        {
          original: '../images/labweken/dag4/1.jpeg',
          thumbnail: '../images/labweken/dag4/1.jpeg',
        },
      ];
      const dag5 = [
        {
          original: '../images/labweken/dag5/3.mp4',
          thumbnail: '../images/labweken/dag5/1.png',
        },
        {
          original: '../images/labweken/dag5/2.mp4',
          thumbnail: '../images/labweken/dag5/1.png',
        },
      ];
      const dag6 = [
        {
          original: '../images/labweken/dag6/1.jpeg',
          thumbnail: '../images/labweken/dag6/1.jpeg',
        },
      ];
      const dag7 = [
        {
          original: '../images/labweken/dag7/1.jpeg',
          thumbnail: '../images/labweken/dag7/1.jpeg',
        },
      ];
      const dag8 = [
        {
          original: '../images/labweken/dag8/1.jpeg',
          thumbnail: '../images/labweken/dag8/1.jpeg',
        },
        {
          original: '../images/labweken/dag8/2.jpg',
          thumbnail: '../images/labweken/dag8/2.jpg',
        },
        // {
        //   original: '../images/labweken/dag8/1.mp4',
        //   thumbnail: '../images/labweken/dag8/1.mp4',
        // },
        // {
        //   original: '../images/labweken/dag8/2.mp4',
        //   thumbnail: '../images/labweken/dag8/2.mp4',
        // },
      ];
      const dag9 = [
        {
          original: '../images/labweken/dag9/1.jpg',
          thumbnail: '../images/labweken/dag9/1.jpg',
        },
        {
          original: '../images/labweken/dag9/2.jpeg',
          thumbnail: '../images/labweken/dag9/2.jpeg',
        },
      ];
    return (
        <Container className='schoolportfolio labweken'>
           <Row >
                <Col xs={12} md={4} className="">
                    <ImageGallery items={dag1} />
               </Col>
                <Col xs={12} md={8} className="">
                    <h2>Labweken</h2>
                    <p>Op dag 1 van de labweken zijn we heel snel van start gegaan na de introductie. We hebben een snelle voorstel ronde gedaan en zijn
                        gelijk aan de slag gegaan met de eerste opdracht, het verzinnen van een 3D logo. Na het zoeken naar overeenkomsten tussen ons, kwamen wij er op uit
                        dat twee groepsgenoten een kat hadden, twee groepsgenoten een vogel, één een kip en ik niks ;). Uiteindelijk zijn we zo op het idee van een kipkat gekomen, die we redelijk snel
                        hadden uitgewerkt daarna.  <br/> <br/>
                        Na het maken van het logo zijn we gelijk gaan brainstormen over wat we gaan maken en dit hebben we gedaan door middel van twee mindmaps. Na een tijdje brainstormen 
                        lag de oplossing best dichtbij, namelijk een grote versie van de kipkat.
                        <br/> <br/>
                        We hebben de groep gelijk in tweeën gesplit May,Tessa en Alyshia gingen aan het werk met de hoofd en Quinten en ik gingen aan de slag met het lichaam. De eerste paar 
                        stappen ging heel snel en zo hadden wij aan het einde van de dag al een lopend telefoonhoesje die wij later ook nog moesten presenteren tijdens de dag afsluiting.
                        Wel kwamen we erachter dat 1 Arduino niet genoeg stroom kon leveren om alle 4 de servo's tegelijk aan te sturen, maar dat hadden we al gauw getackelt door twee arduinos te 
                        gebruiken.  

                    </p>
               </Col>
           </Row>
           <Row >
                <Col xs={12} md={8} className="">
                    <h2>Dag 2</h2>
                    <p>
                        Eind van dag 1 hadden we karton geregeld voor de volgende stappen in het prototype, dus begonnen we dag na de dagelijkse start bijeenkomst gelijk met het knutselen van een 
                        kartonnen lichaam. Nadat we dat in elkaar hadden geknutseld, gingen we de servo's erin vastmaken om de dezelfde loopbeweging na te maken die we onder het telefoonhoesje hadden.
                        Ook kwam ik op het idee om in eerste instantie knieën erin te verwerken zodat die ongeveer net zo loopt als een AT-AT van Star Wars, maar na paar keer testen was het lichaam daar toch al 
                        te zwaar voor. We zijn dus uiteindelijk overgestapt op simpele poten, maar het probleem daarmee was dat we ze niet goed vast kregen aan de servo's waardoor ze dus niet mee draaide.
                        Dit hadden we tijdelijk opgelost door twee spijkertjes aan beide kanten te prikken om het klem te zetten. Het andere deel van ons groepje had ondertussen van karton een hoofd geknutseld en 
                        daarin twee werkende ogen verwerkt.
                        Tijdens dit proces was de tijd wel heel snel gegaan en kwam de dagafsluiting al heel gauw.
                    </p>
               </Col>
               <Col xs={12} md={4} className="">
                    <ImageGallery items={dag2} />
               </Col>
           </Row>
           <Row >
           <Col xs={12} md={4} className="">
                    <ImageGallery items={dag3} />
               </Col>
                <Col xs={12} md={8} className="">
                    <h2>Dag 3</h2>
                    <p>
                     Dag 3 zijn we zo als gewoonlijk weer begonnen met de morning breefing van Chris en daarna zijn we gelijk weer aan de slag gegaan met het hoofd en nadenken over het materiaal. 
                     Het materiaal wat we willen gebruiken moest licht zijn, maar wel stevig genoeg. Uiteindelijk hebben we dus gekozen voor hout en zijn we dat gaan halen bij de Gamma samen met 
                     spijkers, schroeven, houtlijm en een figuurzaag. Toen we terug kwamen begonnen met het nadenken over de groote en dat viel nog niet mee. Het mocht allemaal niet te groot worden,
                     maar alles moest er wel in passen en was dit dus een hele uitdaging op zichzelf. Uiteindelijk zijn we eruit gekomen en zijn we het gaan uittekenen op het hout. 
                     <br/> <br/>
                     Aan mij was 
                     de taak om te gaan zagen. Aangezien wij allemaal designers en developers zijn, viel het zagen nog niet echt mee. De meeste plankjes waren schots en scheef en het duurde even voordat alles gezaagd was.
                     Uiteindelijk hadden we 6 plankjes voor het hoofd en na een tijdje schuren waren ze allemaal vlak en recht. Nu moesten er alleen nog twee gaten in voor het hoofd en gelukkig had 
                     de HHS een mini boor geregeld waar wij gelijk een aantal gaten mee konden boren om zo de ogen eruit te zagen. Na dat we alle plankjes hadden en de ogen eruit waren, was de dag weer voorbij en werd 
                     deze zoals gewoonlijk weer afgesloten door Chris. Op het laaste moment werden we verrast en moesten we voor de 2de keer presenteren om onze kartonnen body met benen te laten zien.
                     </p>
               </Col>              
           </Row>
           <Row >
                <Col xs={12} md={8} className="">
                    <h2>Dag 4</h2>
                    <p>
                        Ook dag 4 begon zoals gewoonlijk met de morning breefing van Chris. Dag 4 was wel een dag waar iedereen het zat was. Quinten en ik waren allebei ziek, maar we waren toch nog gekomen.
                        Door die reden is er die dag dus ook niet veel gebeurd, maar wel wat. We hebben uiteindelijk de ogen vast gemaakt in het hoofd en het hoofd grotendeels aan elkaar vast gelijmt en geschroeft.
                        Het andere deel van het groepje was nog steeds flink aan het testen met het kartonnen prototype hoofd om te kijken of de ogen en de oren op bepaalde dingen reageerden. Dit was handig, want zodra dat
                        klaar is kan het direct op het houten hoofd geupload worden en dan werkt het daar ook. De rest van de dag bestond dan ook uit veel testen met het hoofd en het lichaam. Quinten en ik 
                        hebben veel bewegingen geprobeerd met de benen om het zo goed mogelijk te laten lopen, maar de eerste manier bleek toch de beste te zijn. Uiteindelijk was de dag weer voorbij en werd
                        ook deze dag weer gezamenlijk afgesloten door Chris. 
                        <br/> <br/>
                        Achteraf kwamen we er dat weekend achter dat Quinten het Corona virus te pakken had, maar gelukkig de rest niet.
                    </p>
               </Col>
               <Col xs={12} md={4} className="">
                    <ImageGallery items={dag4} />
               </Col>              
           </Row>
           <Row >
           <Col xs={12} md={4} className="">
                <video controls width="400"className="eindvid" >
                        <source src="../images/labweken/dag5/3.mp4" type="video/mp4"/>
                    </video>
               </Col> 
                <Col xs={12} md={8} className="">
                    <h2>Dag 5</h2>
                    <p>
                    De nieuwe week begon weer netzoals de andere dagen, dat hoef ik denk ik niet meer uit te leggen. In het weekend heb ik nog veel nagedacht hoe we het nu gaan doen 
                    met het lopen van onze kipkat, want waarschijnlijk is die veel te zwaar en hebben we het lopen dus een beetje gelaten die dag voor wat het was. 
                    Wel ben ik gelijk begonnen met het solderen van de kabels in het hoofd. Niemand die bij ons in het groepje ooit gesoldeerd had, dus dat leek mij een leuke taak.
                    Na een snelle uitleg had ik het ook al gauw onder de knie en heb ik alle kabels in het hoofd aan elkaar gesoldeerd en weer goed in de arduino gestoken.
                    Zonder dat breadboard begon het nu ook eindelijk ergens op te lijken we hadden nu dus een hoofd wat compleet op zichzelf werkte.
                    <br/> <br/>
                    Het soldeer werk was dus ook al af voor dat het middag was. Omdat iedereen nog steeds druk bezig was met zijn eigen taak binnen de groep, moest ik toch maar verder met de benen.
                    Ik begon met het nadenken over een logische lengte voor de benen, maar aangezien we alles op schaal deden vanaf ons eerste prototype was dat al snel gebeurd.
                    Gelijk maar uitgetekend en samen met Tessa zijn we ze gaan uitzagen. Na het zagen waren we allemaal wel weer een beetje klaar met de dag en de afsluiting kwam zo al snel dichterbij.
                                      </p>
               </Col>
             
           </Row>
           <Row >
                <Col xs={12} md={8} className="">
                    <h2>Dag 6</h2>
                    <p>
                      Eind van de vorige dag waren Alyshia, Tessa en May ook al ver gekomen met het testen van de sensoren op het kartonnen hoofd. Eerst hebben we heel lang vast gezeten
                      op het testen met een licht sensor om zo te meten of die geaaid werd, maar met het wisselende licht binnen werkte dat niet goed. Uiteindelijk kwamen we op het idee om
                      het te meten met een afstand sensor, deze code hadden we al snel aan de praat, dus konden de sensoren het houten hoofd in. Maar daar kwam probleem nummer duizend. Er 
                      zaten nog geen gaten in het hoofd maar het was al wel aan elkaar gelijmt in plaats van geschroeft, dus moesten we de gaten er voorzichtig in boren zonder dat we iets in het hoofd raakte.
                      Na een tijdje rommelen hiermee was dat gelukt en konden de sensoren erin. Daarna was het een kwestie van draden solderen en code uploaden op de arduino. 
                      <br/> <br/>
                      Gister hadden we natuurlijk ook de benen gezaagd, dus daar konden we ook mee verder. Toen kwamen we erachter dat de helft van de servo's kapot waren en dat de arduino amper 
                      twee servo's aan kon sturen tegelijk. Gelukkig hadden de doceten nog extra servo's en twee batterij houders. We hebben ervoor gekozen om voor elke 2 servo's 1 batterijhouder te doen met 3 batterijen om zo voldoende
                      stroom te kunnen leveren. Na een paar uur rommelen met het aanpassen en veranderen van de servo's kon de kipkat eindelijk al zijn benen bewegen, maar er was al snel duidelijk dat onze kipkat veel te zwaar was. 
                    </p>
               </Col>
               <Col xs={12} md={4} className="">
                    <ImageGallery items={dag6} />
               </Col>              
           </Row>
           <Row >
           <Col xs={12} md={4} className="">
                    <ImageGallery items={dag7} />
               </Col>  
                <Col xs={12} md={8} className="">
                    <h2>Dag 7</h2>
                    <p>
                    De dag begon na de opening door een manier te bedenken om onze kipkat zo licht mogelijk te maken, dus besloten we om de zijkanten van het lichaam er 
                    bijna compleet af te zagen. Dit ging vrij gemakkelijk, maar we kwamen er al gauw achter dat het lichaam zo, zelfs zonder hoofd, nog steeds te zwaar is voor de poten.
                    Dus na lang denken en dingen proberen zat er maar 1 ding op en dat was om wielen aan de onderkant te plaatsen en dan maar doen alsof die loopt. Voor de wielen hadden we dus
                    4 soort van extra poten gemaakt waar allemaal 1 wieltje onder komt. De poten bewogen daarnaast nog wel, maar raakte de grond niet meer dus moesten we hem duwen om vooruit te komen. 
                    Uiteindelijk hebben we dit opgelost door kleine blokjes onder de poten te plakken zodat die net aan de grond raakte en zicht vooruit duwde. 
                    </p>
               </Col>
            
           </Row>
           <Row >
                <Col xs={12} md={8} className="">
                    <h2>Dag 8</h2>
                    <p>
                      Nu het hoofd volledig werkt en hij zichzelf vooruit kan duwen, viel er nog weinig aan te doen naast hem een beetje aan te kleden en de laatste dingetjes solderen.
                      Ik ben dus de laatste kabeltjes gaan solderen terwijl May, Alyshia en Tessa spullen gingen halen om onze kipkat een leuk uiterlijk te geven. 
                      We hadden hem beplakt met dunne schoonmaak doekjes om hem zo een witte vacht te geven en van blauwe veren hadden we hem twee vleugels gegeven. 
                      Dit was opzich wel simpel werk, maar we zijn er wel een hele dag mee bezig geweest om onze kipkat er goed uit te laten zien.
                    </p>
               </Col>
               <Col xs={12} md={4} className="">
                    <ImageGallery items={dag8} />
               </Col>              
           </Row>
           <Row >
           <Col xs={12} md={4} className="">
                    <ImageGallery items={dag9} />
               </Col> 
                <Col xs={12} md={8} className="">
                    <h2>Dag 9</h2>
                    <p>
                    De dag van de expo. We begonnen deze dag nogal hectisch, doordat Quinten vanuit huis heeft gewerkt en we hadden alleen de laatste dag nog om zijn dingen vast te maken.
                    Hij had namelijk vanuit huis aan de snavel gewerkt die door middel van een touch sensor geluid ging maken. We hadden de snavel uiteindelijk snel vast gekregen, maar hij moest wel
                    stroom krijgen via een extra arduino. Na het soldeer werk kwam het grooste probleem, het hele hoofd werkte ineens niet meer op de laatste dag. Na veel rommelen en testen
                    met de draadjes kwamen we erachter dat er een paar aansluitingen los zijn geraakt, maar dit was gelukkig simpel op te lossen. Rond de middag hadden we ons gebied mooi opgemaakt
                    als een natuurlijke habitat voor onze kipkat. Ook hadden we alles nog 1 keer extra getest of alles het nog deed. Daarna zijn we met zijn allen gaan lunchen zodat we 
                    weer fris een fruitig zijn voor de expo. De expo verliep beter dan verwacht er kwamen best veel mensen kijken bij onze kipkat, ondanks dat het loop gedeelte er toch ineens mee uit schoot.
                    Het doel van onze kipkat is dat we extra aandacht wilden omdat het een zielig eenzaam wezen was en gelukkig kreeg hij tijdens de expo veel aandacht. 
                    </p>
               </Col>
             
           </Row>
           <Row> 
                <Col xs={12} md={12} >
                    <h2>Eind video</h2>

                    <video controls width="400"className="eindvid" >
                        <source src="../images/labweken/eind.mp4" type="video/mp4"/>
                    </video>


               </Col>
             
           </Row>
           <Row> 
                <Col xs={12} md={12} className="">
                    <h2>Reflectie</h2>
                    <p>
                      Toen we aankwamen bij de Maakhaven, was mijn eerste gedachte of dit geen drugslab was, maar toen we eenmaal binnenkwamen zag het er wel leuk uit en erg industrieel.
                      Ik was in het begin bang dat ik in een groepje zou zitten waar ik de hele dag Engels moest praten aangezien mijn Engels niet zo best is, maar gelukkig kwam ik in een groepje met allemaal Nederlanders.
                      De opdracht begon vrij stroef en we moesten elkaar een beetje leren kennen, maar uiteindelijk verliep de samenwerking erg goed. 
                      <br/><br/>
                      De gastlessen waren ook erg interessant tijdens de lunch pauzes. Zo vond ik zelf de elephant cam wel een goede uitvinding en was het verhaal erachter ook erg interessant.
                      Ook heb ik er veel geleerd over creatief denken en om de regeltjes een beetje los te laten. Anders hadden we ook nooit op een kruising gekomen tussen een robot, een kip en een kat.
                      <br/><br/>
                      Het werken met de arduino vond ik in de lessen erg leuk en daarmee was ik ook blij met de onderwerp, maar ik vond wel dat het steeds verder afwijkt van de opleiding.
                      Waar we normaal gewoon een website moeten designen en de dingen daar omheen moeten doen, stond ik nu hout te zagen en kabels te solderen. Het was zeker een leuke ervaring, maar ik zou het niet voor een tweede keer voor twee weken non stop hier mee bezig willen zijn. 
                     
                    </p>
               </Col>
             
           </Row>
       </Container>
    )
}

export default Labweken