import styled from 'styled-components'

export const InfoContainer = styled.div`
 color: #FFF;
 background: ${({lightBg}) => (lightBg ? '#f9f9f9' : '#010606')};

 @media screen and (max-width: 768px){
     padding: 100px 0;
 }
`;

export const InfoWrapper = styled.div`
    display: grid;
    z-index: 1;
    height: 800px;
    /* width: 80%;
    max-width: 80%; */
    margin-right: auto;
    margin-left: auto;
    padding: 0 24px;
    justify-content: center;
`;

export const InfoRow = styled.div`
    display: grid;
    grid-auto-columns: minmax(auto, 1fr);
    align-items: center;
    grid-template-areas: ${({imgStart}) => (imgStart ? `'col2 col1'` : `' col1 col2'`)} ;

    @media screen and (max-width: 768px){
        grid-template-areas: ${({imgStart}) => (imgStart ? `'col1' 'col2'` : `'col1 col1' 'col2 col2'` )};
    }
`;


export const Column1 = styled.div`
    margin-bottom: 15px;
    padding: 0 15px;
    grid-area: col1;

`;

export const Column2 = styled.div`
    margin-bottom: 15px;
    padding: 0 15px;
    grid-area: col2;

`;

export const TextWrapper = styled.div`
    max-width:540px;
    padding-top: 0;
    padding-bottom:60px;
`;
export const TopLine = styled.div`
    color: #66EDFC;
    font-size: 20px;
    line-height: 16px;
    font-weight: bold;
    letter-spacing: 1.4px;
    text-transform: uppercase;
    margin-bottom: 16px;
`;
export const Heading = styled.div`
    color: ${({lightText}) => (lightText ? '#f7f8fa' : '#010606')};
    margin-bottom: 24px;
`;

export const Subtitle = styled.div`
    color: ${({darkText}) => (darkText ? '#010606' : '#fff')};
    margin-bottom: 35px;
    max-width: 440px;
`;

export const BtnWrap = styled.div`
    display: flex;
    justify-content: flex-start;
`;
export const ImgWrap = styled.div`
    max-width:555px;
    height: 100%;
`;

export const Img = styled.img`
    width: 100%;
    margin: 0 0 10px 0;
    padding-right: 0;
`;
export const Background = styled.div `
    /* background-image: url("/images/glass-background.png"); */
    background-color: #000;
`;